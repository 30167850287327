<script>
import { useMeta } from 'vue-meta'
import Menu from '@/components/Tiger_Q2/Menu'

export default {
	name: "TigerQ2_list",
	data() {
		return {
			weektab1:1,
			weektab2:6
		};
	},
	setup () {
		useMeta(
		{ 
			title: '熱情燥出來',
			description: '2024/3/6-4/30至指定通路，購買虎牌啤酒全系列，登錄發票就抽Garmin手錶、Wilson FIBA籃球' 
		})		
	},
	components: {
		Menu
	},
	computed: {
	},
	watch: {
	},
	mounted() {
	},
	methods: {
		changeTab(id,e) {
			let vm = this
			if(e == "ball"){
				vm.weektab1 = id;
			}
			if(e == "watch"){
				vm.weektab2 = id;
			}
		},
	},
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
